import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="header-container px-3 ">
      <nav className="navbar navbar-expand-lg ">
        <div className="container border-1 ">
          {/* Logo */}
          <a className="navbar-brand" href="/">
            <img src="/images/janalogo.png" alt="Brand Logo" />
          </a>

          {/* Hamburger Toggle Button */}
          <button
            className="navbar-toggler custom-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar Links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto gap-5r">
              <li className="nav-item">
                <button className="nav-link" to="/" href="/">
                  Početna
                </button>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gallery" href="/">
                  Galerija
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Kontakt
                </Link>
              </li>
              <li className=" align-items-center d-none d-lg-flex">
                <Link
                  to="https://www.instagram.com/apartman_jana_bijeljina/"
                  target="_blank"
                  className="text-decoration-none text-black "
                >
                  <img
                    src="/images/Instagram.png"
                    className="cursor-pointer "
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
